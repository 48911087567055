import React , { useState, useEffect } from "react";
// import "@google/model-viewer";
import '@google/model-viewer';
import * as THREE from 'three';
import GoogleImage from './image/static/Freeze_with_Animation.glb'


function App() {

  const [modelData, setModelData] = useState([]);
  const [baseColor, setBaseColor] = useState("#ffffff");
  const [textureUrl, setTextureUrl] = useState(null);

  useEffect(() => {
    // Fetch data from the actual API
    const fetchModelData = async () => {
      try {
        const response = await fetch("https://api.govhome.theclientdemos.com/api/products/");
        const data = await response.json();
        
        // Set the data from API response
        setModelData(data);
        console.log("data: " + data);
      } catch (error) {
        console.error("Error fetching model data:", error);
      }
    };

    fetchModelData();
  }, []);

  const handleMaterialChange = (product) => {
    if (product.type === "hex") {
      setBaseColor(product.source); // Apply base color
      setTextureUrl(null); // Remove texture if any
    } else if (product.type === "imagesrc") {
      setTextureUrl(product.image); // Apply base texture
      setBaseColor(null); // Remove color if any
    }
  };
  
  return (
    <div className="App">
       {modelData.map((product) => (
        <button key={product.product_id} onClick={() => handleMaterialChange(product)}>
          {product.description}
        </button>
       ))}
      

        <model-viewer
          src={GoogleImage}
          alt="A 3D model"
          auto-rotate
          camera-controls
          environment-image="neutral"
          style={{ width: '100%', height: '500px' }}
        ></model-viewer>
    </div>
  );
}

export default App;
